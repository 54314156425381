import { graphql } from "gatsby"
import React, { FC } from "react"
import { Layout } from "src/components/Layout"
import { Props } from "./types"
import * as styles from "./styles.module.scss"
import he from "he"
import BlogSubFooter from "src/components/BlogSubFooter"
import GetStarted from "src/components/Banners/GetStarted"
import SubNavBlog from "src/components/SubNavBlog"
import BlogArticles from "src/components/BlogArticles"

const Template: FC<Props> = ({
  data: {
    file: { childYaml: texts },
    allWordpressPost: { nodes },
    allWordpressCategory: { nodes: categories },
    relatedPosts: { nodes: relatedNodes },
  },
}) => {
  const [post] = nodes
  return (
    <Layout title={he.decode(post.title)} className={styles.main}>
      <SubNavBlog categories={categories} active={post.categories[0].name} />
      <div className={`${styles.header} `}>
        <h1
          className={styles.title}
          dangerouslySetInnerHTML={{ __html: post.title }}
        />
        <span
          className={styles.author}
          dangerouslySetInnerHTML={{ __html: post.author.name }}
        />
        <span className={styles.date}>
          {texts.lastUpdate} {post.date}
        </span>

        {post.jetpack_featured_media_url && (
          <div className={styles.headerImg}>
            <img src={post.jetpack_featured_media_url} />
          </div>
        )}
      </div>
      <div className={`content-wrapper ${styles.post}`}>
        <div dangerouslySetInnerHTML={{ __html: post.content }} />
      </div>
      <BlogArticles nodes={relatedNodes} />
      <div className={`content-wrapper ${styles.subfooter}`}>
        <BlogSubFooter />
        <GetStarted className={styles.banner} />
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($slug: String!, $ymlPath: String!) {
    file(absolutePath: { eq: $ymlPath }) {
      childYaml {
        lastUpdate
        minRead
      }
    }
    allWordpressPost(filter: { slug: { eq: $slug } }) {
      nodes {
        title
        content
        date(formatString: "MMMM D, YYYY")
        jetpack_featured_media_url
        author {
          name
          avatar_urls {
            wordpress_96
          }
        }
        categories {
          name
          slug
        }
      }
    }
    relatedPosts: allWordpressPost(limit: 13) {
      nodes {
        slug
        title
        content
        date(formatString: "MMMM D, YYYY")
        jetpack_featured_media_url
        author {
          name
          avatar_urls {
            wordpress_96
          }
        }
        categories {
          name
          slug
        }
      }
    }

    allWordpressCategory(filter: { name: { ne: "Uncategorized" } }) {
      nodes {
        name
        slug
      }
    }
  }
`

export default Template
